import React from 'react'

import { CollaboratorImages } from '@components/About/CollaboratorImages'
import { Text, Flex } from 'theme-ui'
import { Link } from 'gatsby'
import { Layout } from '@components/Layout'
import { SEO } from '@components/SEO'
import LongArrowLeft from '../assets/long-arrow-left.svg'

const Collaborators = () => (
  <Layout page="collaborators">
    <SEO title="Collaborators" />
    <Flex>
      <Link to="/about" style={{ textDecoration: 'none' }}>
        <Text
          sx={{
            color: 'grey',
            fontFamily: 'Futura Medium',
            fontSize: [5],
            p: [4],
          }}
        >
          <LongArrowLeft
            style={{
              marginBottom: '-7px',
              marginRight: '8px',
            }}
            width={24}
            height={32}
          />{' '}
          Back
        </Text>
      </Link>
    </Flex>
    <CollaboratorImages />
  </Layout>
)

export default Collaborators

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Box, Flex, Text } from 'theme-ui'
import { AboutImageCard } from '../OurPeople/AboutImages'

export const CollaboratorImages = () => {
  const data = useStaticQuery(
    graphql`
      query contentfulCollaboratorImages {
        collaboratorImages: allContentfulAboutImage(
          filter: { group: { in: "Participants" } }
        ) {
          nodes {
            headshotImage {
              fixed(height: 300, width: 300) {
                ...GatsbyContentfulFixed
              }
            }
            group
            name
            title
            priority
          }
        }
      }
    `
  )

  data.collaboratorImages.nodes.sort((a, b) =>
    a.priority > b.priority ? 1 : -1
  )

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        mb: ['100px'],
      }}
    >
      <Box>
        <Text
          variant="heading.med"
          sx={{
            px: 4,
          }}
        >
          Collaborators
        </Text>
      </Box>
      <Flex
        sx={{
          px: [4],
          flexWrap: 'wrap',
        }}
      >
        {data.collaboratorImages.nodes.map(node => (
          <AboutImageCard aboutImage={node} />
        ))}
      </Flex>
    </Flex>
  )
}
